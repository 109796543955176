.MuiDataGrid-cell[data-field*="problem"]{
    display: block !important;
    text-align: center !important;
    width: 100%;
  }

  .MuiDataGrid-cell[data-field*="score"]{
    display: block !important;
    text-align: center !important;
    width: 100%;
  }
  
  